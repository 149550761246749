import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Box } from "@mui/material"
import { instanceOf, string } from "prop-types"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import mixpanel from "mixpanel-browser"
import { actionCreators as matchProductActions } from "../../../../ducks/matches"
import useRestricted from "../../../../hooks/use-restricted"
import { currencyToSymbol } from "../../../../util/currencies"
import { PERM_UPDATE_MATCHES } from "../../../../util/permissions"
import { getBestProductContentV1 } from "../../../../util/volumes"
import FactorInput from "../../../factor-input/FactorInput"
import { ORIGIN_SEARCH } from "../../../../util/match-origin"

function XFactor({ referenceProduct, daltixProduct, matchId }) {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const isUpdater = useRestricted({ requiredPermissions: PERM_UPDATE_MATCHES })
  const matched = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data,
  )
  const isDisabled = !matchId

  const updatedFactor = matched?.mult_factor || daltixProduct.matched?.mult_factor
  const enforcedFactor = updatedFactor || 1
  const isNullFactor = updatedFactor === undefined || updatedFactor === null

  const referenceProductId = referenceProduct.id
  const refProdName = referenceProduct.name
  const refProdImages = referenceProduct.images
  const listings = referenceProduct.listings?.[0]
  const refProdPrice = listings?.price
  const refProdCurrency = listings?.currency
  const refProdNormalizedUnit = referenceProduct.normalizedUnit
  const refProdNormalizedPrice = referenceProduct.normalizedPrice
  const refProdUrl = referenceProduct.url
  const refProdBrand = referenceProduct.brand
  const refProdContentsV1 = referenceProduct.contents_v1
  const refProdCurrencySymbol = currencyToSymbol(refProdCurrency)
  const refProdBestContent = useMemo(
    () => (refProdContentsV1 ? getBestProductContentV1(refProdContentsV1) : {}),
    [refProdContentsV1],
  )

  const {
    multiplier: refProdContentMultiplier = 1,
    value: refProdContentValue,
    unit: refProdContentUnit,
  } = refProdBestContent

  const daltixId = daltixProduct.id
  const dxProdName = daltixProduct.name
  const dxProdImages = daltixProduct.images
  const dxProdUrl = daltixProduct.url
  const dxProdPrice = daltixProduct.price
  const dxProdCurrency = daltixProduct.currency
  const dxProdBrand = daltixProduct.brand
  const contentsV1 = daltixProduct.contents_v1
  const dxProdCurrencySymbol = currencyToSymbol(dxProdCurrency)
  const dxProdBestContent = useMemo(
    () => (contentsV1 ? getBestProductContentV1(contentsV1) : {}),
    [contentsV1],
  )
  const {
    multiplier: dxProdContentMultiplier = 1,
    value: dxProdContentValue,
    unit: dxProdContentUnit,
  } = dxProdBestContent

  const handleFactorChange = (newFactor) => {
    dispatch(
      matchProductActions.updateMatchBasicInfo({
        daltixProductId: daltixId,
        factor: newFactor,
        referenceProductId,
        source: ORIGIN_SEARCH,
      }),
    )
    mixpanel.track("Product Search - update Xfactor")
  }

  const onClose = (e) => {
    e.stopPropagation()
    setOpen(false)
  }

  const onOpen = () => {
    setOpen(true)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={isDisabled ? undefined : onOpen}
      sx={{
        cursor: isDisabled ? undefined : "pointer",
        ":hover svg": {
          visibility: "visible",
        },
        ".factor-button": {
          backgroundColor: "transparent",
          border: "none",
          fontWeight: 400,
          fontFamily: "Graphie",
        },
        // disable ripple effect
        [`.factor-button span:last-child`]: {
          display: "none",
        },
      }}
    >
      {!isDisabled && (
        <EditOutlinedIcon sx={{ visibility: open ? "visible" : "hidden" }} />
      )}
      <FactorInput
        style={{ height: "100%", pointerEvents: "none" }}
        className="button-fheight"
        open={open}
        onClose={onClose}
        readOnly={!isUpdater || isDisabled}
        productName={refProdName}
        productPicture={refProdImages}
        productCurrency={refProdCurrencySymbol}
        productPrice={refProdPrice}
        productNormalizedPrice={refProdNormalizedPrice}
        productNormalizedUnit={refProdNormalizedUnit}
        productUnit={refProdContentUnit}
        productBrand={refProdBrand}
        productUrl={refProdUrl}
        matchName={dxProdName}
        matchUnit={dxProdContentUnit}
        matchPicture={dxProdImages}
        matchPrice={dxProdPrice}
        matchCurrency={dxProdCurrencySymbol}
        matchBrand={dxProdBrand}
        matchUrl={dxProdUrl}
        defaultProductMultiplier={refProdContentMultiplier}
        defaultProductContent={refProdContentValue}
        defaultMatchMultiplier={dxProdContentMultiplier}
        defaultMatchValue={dxProdContentValue}
        currentFactor={enforcedFactor}
        isNullFactor={isNullFactor}
        onChange={handleFactorChange}
      />
    </Box>
  )
}

XFactor.propTypes = {
  referenceProduct: instanceOf(Object).isRequired,
  daltixProduct: instanceOf(Object).isRequired,
  matchId: string.isRequired,
}

export { XFactor }
