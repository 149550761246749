import React, { useMemo } from "react"
import clsx from "clsx"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import { LoaderSpinner, Table } from "../../ui"

import styles from "./AssortmentAnalysisTable.module.scss"
import {
  AssortmentAnalysisArticleDataCell,
  AssortmentAnalysisColumnHeader,
  AssortmentAnalysisColumnHeaderDimension,
  AssortmentAnalysisColumnHeaderShop,
  AssortmentAnalysisRowHeader,
  NoResultsDisplay,
} from "./partials"
import {
  useAssortmentAnalysisPageMeta,
  useAssortmentAnalysisTable,
  useDimensionFilter,
  useFilterOptions,
  useSelectorSeletedOptions,
} from "../hooks"
import useShops from "../../../hooks/use-shops"
import { EMPTY_FILTERS } from "../AssortmentAnalysisFilters"
import { useHasAAToolShopFilter } from "../../../integrations/split"

export function AssortmentAnalysisTable() {
  const hasShopFilterEnabled = useHasAAToolShopFilter()
  const shouldFilterShop = hasShopFilterEnabled || false
  const { setFilterOptions } = useFilterOptions()
  const { data, isSuccess, isRefetching } = useAssortmentAnalysisTable()
  const { hasArticles, hasShops } = useAssortmentAnalysisPageMeta()
  const { dimension: dimensionFilter } = useDimensionFilter()
  const {
    selectedFilters: { shop: selectedShop },
  } = useSelectorSeletedOptions()
  const { getByCode, items } = useShops()

  const columnData = useMemo(() => {
    if ((isSuccess || isRefetching) && data) {
      return data.map(({ dimension, shops }) => ({ dimension, ...shops }))
    }

    return null
  }, [data, isRefetching, isSuccess])

  const columnDefs = useMemo(() => {
    if (Array.isArray(columnData)) {
      if (columnData.length === 0) {
        return []
      }

      const filterSelectedShops = (shopColumns) => {
        const shopsToKeep = shopColumns.map((shopColumn) => {
          const { dimension, ...shopList } = shopColumn
          return Object.fromEntries(
            Object.entries(shopList).filter(([shop]) => {
              if (!shouldFilterShop) {
                return true
              }
              if (Array.isArray(selectedShop) && selectedShop.length > 0) {
                return selectedShop.includes(shopList[shop].shop)
              }
              return shopList[shop].articles.length > 0
            }),
          )
        })
        const shops = Object.assign({}, ...new Set(shopsToKeep))
        const sortedShops = Object.entries(shops).sort((a, b) => {
          const shopA = getByCode(a[1].shop)
          const shopB = getByCode(b[1].shop)

          if (shopA.name && shopB.name) {
            return shopA.name.localeCompare(shopB.name)
          }
          return false
        })

        const startShops = Object.keys(items).filter((shop) => items[shop].isOwnShop)
        if (startShops.length > 0) {
          // sort the shops and reverse the order to insert them at the beginning alphabetically
          const startShopsSorted = startShops
            .sort((a, b) => {
              const shopA = getByCode(a)
              const shopB = getByCode(b)

              if (shopA.name && shopB.name) {
                return shopA.name.localeCompare(shopB.name)
              }
              return false
            })
            .reverse()
          startShopsSorted.forEach((startShop) => {
            const idx = sortedShops.findIndex((shop) => shop[1].shop === startShop)
            if (idx !== -1) {
              sortedShops.unshift(sortedShops.splice(idx, 1)[0])
            }
          })
        }

        return sortedShops
      }

      const shops = filterSelectedShops(columnData)

      return [
        {
          accessorKey: "dimension",
          header: () => (
            <AssortmentAnalysisColumnHeader>
              <AssortmentAnalysisColumnHeaderDimension
                dimensionType={dimensionFilter}
              />
            </AssortmentAnalysisColumnHeader>
          ),
          cell: (c) => <AssortmentAnalysisRowHeader dimension={c.getValue()} />,
          footer: null,
        },
        ...shops.map(([shopCountryCode, { country, shop }]) => ({
          accessorKey: shopCountryCode,
          header: () => (
            <AssortmentAnalysisColumnHeader>
              <AssortmentAnalysisColumnHeaderShop id={shop} country={country} />
            </AssortmentAnalysisColumnHeader>
          ),
          cell: (c) => (
            <AssortmentAnalysisArticleDataCell articles={c.getValue().articles} />
          ),
          footer: null,
        })),
      ]
    }

    return null
  }, [columnData, items, shouldFilterShop, selectedShop, getByCode, dimensionFilter])

  if (columnData === null || columnDefs === null) {
    return <LoaderSpinner />
  }

  if (isRefetching) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <LoaderSpinner />
      </Box>
    )
  }

  if (!hasArticles) {
    return (
      <Box
        data-testid="no-results"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <NoResultsDisplay src="/assets/images/undraw_empty_re_opql.svg">
          It the appears we have no articles to display, using the currently active
          criteria (filters).
        </NoResultsDisplay>
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={() => setFilterOptions(EMPTY_FILTERS)}
        >
          Clear all filters
        </Button>
      </Box>
    )
  }

  if (!hasShops) {
    return (
      <NoResultsDisplay src="/assets/images/undraw_empty_re_opql.svg">
        It the appears we have no shops to display, using the currently active criteria
        (filters).
      </NoResultsDisplay>
    )
  }

  return (
    <div className={styles.container}>
      <Table.Root
        className={clsx("aatoolCaptureDiv", "grow", styles.table)}
        columns={columnDefs}
        data={columnData}
        data-testid="assortment-analysis-table"
        stickyXHeader
        stickyYHeader
        stripedRows
        xHeaderId="dimension"
      />
    </div>
  )
}
