import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import { Badge, Tooltip } from "@mui/material"
import styles from "./ArticleDetailEntry.module.scss"

export function ArticleDetailEntry({ title, value, slot, debug, unsure, unvalidated }) {
  return (
    <div className={styles.container}>
      <Tooltip
        title={unvalidated ? "Unvalidated attribute" : unsure ? "Unsure attribute" : ""}
        placement="right"
      >
        <Badge
          color={unvalidated ? "error" : unsure ? "ochre" : "none"}
          variant="dot"
          invisible={!unsure && !unvalidated}
          sx={{ paddingRight: 1 }}
        >
          <h1 className={styles.title}>{title}</h1>
        </Badge>
      </Tooltip>
      <div className={styles.content}>
        {slot}
        <p className={clsx(styles.value, debug && styles.debug)}>{value}</p>
      </div>
    </div>
  )
}

ArticleDetailEntry.propTypes = {
  title: PropTypes.string.isRequired,
  slot: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debug: PropTypes.bool,
  unsure: PropTypes.bool,
  unvalidated: PropTypes.bool,
}

ArticleDetailEntry.defaultProps = {
  slot: null,
  value: "",
  debug: false,
  unsure: false,
  unvalidated: false,
}
