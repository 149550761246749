import React, { memo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { get } from "lodash"
import { Link } from "react-router-dom"
import { Button, Icon, Card, AreaBar, AppArea, View } from "@daltix/ui-react"
import mixpanel from "mixpanel-browser"
import queryString from "query-string"
import Header from "../../components/header/Header"
import RestrictedContent from "../../components/restricted-content/RestrictedContent"
import {
  FEAT_APPS,
  FEAT_MATCHING_TOOL,
  FEAT_REPORTS,
  FEAT_UPLOAD_FILE,
} from "../../util/features"
import {
  PERM_READ_APPS,
  PERM_READ_MATCHES,
  PERM_READ_REPORTS,
  PERM_UPLOAD_FILES,
} from "../../util/permissions"
import HtmlTitle from "../../components/html-title/HtmlTitle"
import "./Portal.sass"
import { matchingEngineRoutes } from "../matching-engine/MatchingEngine"
import {
  QP_DIMENSION,
  QP_PAGE,
  QP_PAGE_SIZE,
  QP_PRODUCT_GROUP,
  QP_SORT,
  QP_STATUS,
} from "../../util/query-param"
import { STATUS_APPROVED, STATUS_FLAGGED } from "../../util/match-status"
import {
  assortmentFeatureFlags,
  assortmentToolRoutes,
} from "../assortment-tool/AssortmentTool"
import {
  DIMENSION_PRICE,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
} from "../../ducks/pages/assortment-analysis/constants"
import { SORT_ASC, createSortProperty } from "../../util/sorting"
import { useHasMatchOverviewFlags } from "../../integrations/split"
import { useProductGroupFilter } from "../../components/assortment-analysis/hooks"
import * as Actions from "../../ducks/pages/assortment-analysis/action-creators"

function PortalPresenter() {
  const [disableAATooL, setDisableAATool] = useState(true)
  const [productGroup, setProductGroup] = useState(null)
  const hasMatchOverviewFlags = useHasMatchOverviewFlags()
  const defaultStatus = [STATUS_APPROVED]
  if (hasMatchOverviewFlags) {
    defaultStatus.push(STATUS_FLAGGED)
  }

  const user = useSelector((state) => get(state, ["user", "user"], {}))
  const dispatch = useDispatch()
  const [productGroupId] = useProductGroupFilter()

  const productGroups = useSelector(
    (state) =>
      get(state, [
        "pages",
        ASSORTMENT_ANALYSIS_PAGE_KEY,
        ASSORTMENT_ANALYSIS_PAGE_FILTERS_OUTLINE_KEY,
        ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
      ])?.data,
  )

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && !productGroups) {
      dispatch(Actions.triggerGetCustomerAssortmentOutline())
    } else if (
      productGroups &&
      productGroups.length > 0 &&
      (!disableAATooL || !productGroup)
    ) {
      setDisableAATool(false)
      setProductGroup(
        productGroups?.find((opt) => opt.id === productGroupId) || productGroups?.[0],
      )
    }
  }, [user, disableAATooL, dispatch, productGroup, productGroupId, productGroups])

  return (
    <div>
      <Header className="page-header" title="Navigator" />
      <View>
        <HtmlTitle>Home</HtmlTitle>
        <AreaBar title="Home" />
        <AppArea>
          <div className="dx-card-group">
            <RestrictedContent
              requiredFeatures={FEAT_UPLOAD_FILE}
              requiredPermissions={PERM_UPLOAD_FILES}
            >
              <div className="dx-one-third">
                <Card actionable title="Upload Hub" icon="press">
                  <div style={{ marginBottom: "1rem" }}>
                    <p>Here you can upload your files.</p>
                  </div>
                  <Link to="/upload/file">
                    <Button color="primary" iconRight={<Icon type="right" />}>
                      Upload Files
                    </Button>
                  </Link>
                </Card>
              </div>
            </RestrictedContent>
            <RestrictedContent
              requiredFeatures={[
                [FEAT_APPS], // or
                [FEAT_REPORTS],
              ]}
              requiredPermissions={[
                [PERM_READ_APPS], // or
                [PERM_READ_REPORTS],
              ]}
            >
              <div className="dx-one-third">
                <Card actionable title="Apps &amp; Reports" icon="delivery">
                  <div style={{ marginBottom: "1rem" }}>
                    <p>
                      <strong>All your Daltix apps and reports in one place.</strong>{" "}
                      Gain insights on your products through Daltix apps, export your
                      data or download your latest updated reports.
                    </p>
                  </div>
                  <Link to="/dashboards">
                    <Button color="primary" iconRight={<Icon type="right" />}>
                      View Apps and Reports
                    </Button>
                  </Link>
                </Card>
              </div>
            </RestrictedContent>
            <RestrictedContent
              requiredFeatures={FEAT_MATCHING_TOOL}
              requiredPermissions={PERM_READ_MATCHES}
            >
              <div className="dx-one-third">
                <Card actionable title="Matching" icon="collaboration">
                  <div style={{ marginBottom: "1rem" }}>
                    <p>
                      Match your reference products with those in the market.{" "}
                      <strong>Review, adjust and make matches</strong> to get the most
                      complete and best quality data into your apps and reports.
                    </p>
                  </div>
                  <div className="card-buttons">
                    <Link
                      to="/matches/review"
                      onClick={() => mixpanel.track("MT - Home Review Matches Clicked")}
                    >
                      <Button color="primary" iconRight={<Icon type="right" />}>
                        Review Matches
                      </Button>
                    </Link>
                    <Link
                      to="/matches/match"
                      onClick={() => mixpanel.track("MT - Home Make Matches Clicked")}
                    >
                      <Button color="primary" iconRight={<Icon type="right" />}>
                        Make Matches
                      </Button>
                    </Link>

                    <Link
                      to={{
                        pathname: matchingEngineRoutes.overview,
                        search: queryString.stringify({
                          [QP_PAGE]: 1,
                          [QP_PAGE_SIZE]: 25,
                          [QP_STATUS]: defaultStatus,
                        }),
                      }}
                      onClick={() =>
                        mixpanel.track("MT - Home Matches Overview Clicked")
                      }
                    >
                      <Button color="primary" iconRight={<Icon type="right" />}>
                        Matches Overview
                      </Button>
                    </Link>
                  </div>
                </Card>
              </div>
            </RestrictedContent>
            <RestrictedContent requiredFeatureFlags={assortmentFeatureFlags}>
              <div className="dx-one-third">
                <Card actionable title="Assortment Analysis" icon="chart">
                  <div style={{ marginBottom: "1rem" }}>
                    <p>
                      Visualise and{" "}
                      <strong>compare your assortment with the competition</strong> on
                      Price, Format and other dimensions
                    </p>
                  </div>
                  <div className="card-buttons">
                    <Link
                      to={{
                        pathname: assortmentToolRoutes.analysis,
                        search: queryString.stringify({
                          [QP_DIMENSION]: DIMENSION_PRICE,
                          [QP_SORT]: createSortProperty("dimension", SORT_ASC),
                          [QP_PRODUCT_GROUP]: productGroup?.id,
                        }),
                      }}
                      onClick={() =>
                        mixpanel.track("MT - Home Assortment Analysis Clicked")
                      }
                    >
                      <Button
                        color="primary"
                        iconRight={<Icon type="right" />}
                        disabled={disableAATooL}
                      >
                        Grid view
                      </Button>
                    </Link>
                  </div>
                </Card>
              </div>
            </RestrictedContent>
          </div>
        </AppArea>
      </View>
    </div>
  )
}

export function PortalFallback({ title }) {
  return (
    <div style={{ height: "100%" }}>
      <Header className="page-header" />
      <HtmlTitle>{title}</HtmlTitle>
      <View>
        <Card
          full
          className="loader"
          waiting
          style={{
            height: "100%",
            backgroundColor: "transparent",
            boxShadow: "none",
            border: "none",
          }}
        />
      </View>
    </div>
  )
}

export function PortalError({ message, title }) {
  return (
    <div style={{ height: "100%" }}>
      <Header className="page-header" title="Navigator" />
      <HtmlTitle>{title}</HtmlTitle>
      <View>
        <Card
          className="error-message-card"
          full
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="dx-error-message">
            <Icon type="warning" className="dx-color-red" />
            <div>
              <h2>Oops! Something went wrong :(</h2>
              <br />
              {message}
            </div>
          </div>
        </Card>
      </View>
    </div>
  )
}

PortalError.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
}

PortalError.defaultProps = {
  title: "Home",
}

PortalFallback.propTypes = {
  title: PropTypes.string,
}

PortalFallback.defaultProps = {
  title: "Home",
}

export default memo(PortalPresenter)
