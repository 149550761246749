import React, { useMemo } from "react"
import PlaceIcon from "@material-ui/icons/PlaceRounded"
import PlaceOffIcon from "@material-ui/icons/LocationOffRounded"
import LinkOffIcon from "@material-ui/icons/LinkOffRounded"
import { TextRow, RectShape } from "react-placeholder/lib/placeholders"
import Tooltip from "@material-ui/core/Tooltip"
import { Button, Icon } from "@daltix/ui-react"
import moment from "moment/moment"
import FactorInput from "../factor-input/FactorInput"
import { titleCase, contentString } from "../../util/strings"
import Image from "../img/Image"
import EANList from "../ean-list/EANList"
import StatusChip from "../status-chip"
import CountryFlag from "../country-flag/CountryFlag"
import { currencyToSymbol } from "../../util/currencies"
import {
  STATUS_REVIEW,
  STATUS_APPROVED,
  STATUS_DISCARDED,
} from "../../util/match-status"
import { sourceCode2data } from "../../util/match-source"
import { getBestProductContentV1, getContentsV1AsArray } from "../../util/volumes"
import useRestricted from "../../hooks/use-restricted"
import {
  PERM_APPROVE_MATCHES,
  PERM_CREATE_MATCHES,
  PERM_DISCARD_MATCHES,
  PERM_UPDATE_MATCHES,
} from "../../util/permissions"
import useShop from "../../hooks/use-shop"
import QualityIndicator from "../quality-indicator"
import { DX_SOURCE_FIELDTAKE, DX_SOURCE_SCRAPING } from "../../util/dx-product-source"
import ComparisonPopup from "../comparison-popup"
import {
  daltixProductToComparableTradeItem,
  referenceProductToComparableTradeItem,
  tradeItemComparableFields,
} from "../../util/comparison-formatter"
import { useHasMatchTypeStatusChip } from "../../integrations/split"

import "./style.sass"

function MatchSourcePlaceHolder() {
  return (
    <TextRow
      color="#eee"
      style={{
        width: 110,
        height: 16,
        position: "absolute",
        bottom: 0,
        left: "1em",
        right: "1em",
        opacity: 0.9,
      }}
    />
  )
}

function ButtonsPlaceholder() {
  return (
    <div>
      <RectShape
        color="#eee"
        style={{ width: 175, height: 25, display: "inline-block" }}
      />
      <RectShape
        color="trasparent"
        style={{ width: 200, height: 4, display: "inline-block" }}
      />
      <RectShape
        color="#eee"
        style={{ width: 175, height: 25, display: "inline-block" }}
      />
    </div>
  )
}

function ProductPlaceHolder({ displaySource = false }) {
  const randomWith = (min, max) =>
    `${Math.round(100 * (Math.random() * (max - min) + min))}%`

  const nameWidth = useMemo(() => randomWith(0.5, 0.5), [])
  const contentsWidth = useMemo(() => randomWith(0.3, 0.3), [])
  const shopWidth = useMemo(() => randomWith(0.25, 0.25), [])

  return (
    <div className="dx-reference-summary grow show-loading-animation">
      <div className="main-info">
        <div className="dx-match-image">
          <RectShape
            color="#ccc"
            style={{ width: 120, height: 120, display: "inline-block" }}
          />
          {displaySource && <MatchSourcePlaceHolder />}
        </div>
        <div className="dx-reference-info grow">
          <TextRow color="#888" style={{ width: nameWidth, height: 17 }} />
          <TextRow color="#eee" style={{ width: contentsWidth, height: 9 }} />
          <br />
          <div>
            <TextRow color="#bbb" style={{ width: "15%", display: "inline-block" }} />{" "}
            <TextRow
              color="#ccc"
              style={{ width: "15%", display: "inline-block", heigh: 10 }}
            />
          </div>
          <RectShape color="transparent" style={{ width: 120, height: 5 }} />
          <TextRow
            color="#eee"
            style={{ width: "10%", display: "inline-block", height: 9 }}
          />
          <div>
            <TextRow
              color="#aaa"
              style={{ width: shopWidth, display: "inline-block", height: 9 }}
            />{" "}
            <TextRow
              color="#eee"
              style={{ width: "35%", display: "inline-block", height: 9 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function MatchItem({
  loading,
  loadingRefProd,
  daltixProduct,
  daltixProduct: {
    article_nr: dxProdArticleNr,
    name: dxProdName,
    images: dxProdImages,
    display_url: dxProdUrl,
    price: dxProdPrice,
    currency: dxProdCurrency,
    brand: dxProdBrand,
    shop: dxProdShop,
    country: dxProdCountry,
    outdated: dxProductOutdated,
    eans = [],
    contents_v1: contentsV1,
    source: dxProdSource,
  } = {},
  mult_factor: multFactor,
  match_source: matchSource,
  status,
  matchId,
  match_type: matchType,
  referenceProduct,
  referenceProduct: {
    name: refProdName,
    images: refProdImages,
    listings: [
      {
        price: refProdPrice,
        currency: refProdCurrency,
        normalizedUnit: refProdNormalizedUnit,
        normalizedPrice: refProdNormalizedPrice,
        url: refProdUrl,
      } = {},
    ] = [],
    brand: refProdBrand,
    contents_v1: refProdContentsV1,
  } = {},
  labels,
  comment,
  comparisonOpen,
  // events
  onFactorChange,
  onNavigateToStore,
  onLabelsChange,
  onCommentChange,
  onComparisonOpen,
  onComparisonClose,
}) {
  const dxProdCurrencySymbol = currencyToSymbol(dxProdCurrency)
  const refProdCurrencySymbol = currencyToSymbol(refProdCurrency)

  const enforcedFactor = multFactor || 1
  const isNullFactor = multFactor === undefined || multFactor === null

  const isUpdater = useRestricted({ requiredPermissions: PERM_UPDATE_MATCHES })

  // daltix prod contents
  const dxProdBestContent = useMemo(
    () => (contentsV1 ? getBestProductContentV1(contentsV1) : {}),
    [contentsV1],
  )

  const {
    multiplier: dxProdContentMultiplier = 1,
    value: dxProdContentValue,
    unit: dxProdContentUnit,
  } = dxProdBestContent

  const contentsToDisplay = useMemo(
    () => (contentsV1 ? getContentsV1AsArray(contentsV1) : []),
    [contentsV1],
  )

  const dxProdContentsText = useMemo(
    () => contentsToDisplay.map(contentString).join(" / "),
    [contentsToDisplay],
  )

  // reference product contents
  const refProdBestContent = useMemo(
    () => (refProdContentsV1 ? getBestProductContentV1(refProdContentsV1) : {}),
    [refProdContentsV1],
  )

  const {
    multiplier: refProdContentMultiplier = 1,
    value: refProdContentValue,
    unit: refProdContentUnit,
  } = refProdBestContent

  const { name: dxProdFriendlyShopName } = useShop(dxProdShop)

  const {
    code: matchSourceCode,
    badgeLabel: matchSourceBadgeLabel,
    title: matchSourceTitle,
  } = sourceCode2data(matchSource) || {}

  const hasMatchTypeStatusChip = useHasMatchTypeStatusChip()

  const renderLabel = () => {
    if (hasMatchTypeStatusChip) {
      return (
        <>
          {matchSource && (
            <div className="dx-label-status">
              <StatusChip
                status={status}
                matchId={matchId}
                outdated={dxProductOutdated}
                source={dxProdSource}
                matchType={matchType}
                matchSource={matchSource}
              />
            </div>
          )}
          {!matchSource && status && <MatchSourcePlaceHolder />}
        </>
      )
    }
    return (
      <>
        {matchSourceCode && (
          <div
            className={`dx-label dx-label-${matchSourceCode}`}
            title={matchSourceTitle}
          >
            {matchSourceBadgeLabel}
          </div>
        )}
        {!matchSourceCode && status && <MatchSourcePlaceHolder />}
      </>
    )
  }

  if (loading) {
    return <ProductPlaceHolder />
  }

  return (
    <div
      className={`match-item-root dx-match-summary ${
        dxProductOutdated ? "outdated" : ""
      }`}
    >
      {referenceProduct && daltixProduct && (
        <ComparisonPopup
          items={[
            referenceProductToComparableTradeItem(referenceProduct),
            daltixProductToComparableTradeItem(daltixProduct),
          ]}
          open={comparisonOpen}
          onClose={onComparisonClose}
          options={{
            center: true,
            titleAttributeName: "name",
            subTitleAttributeName: "brand",
            attributes: tradeItemComparableFields,
          }}
        />
      )}

      <div className="main-info">
        <div className="picture-container">
          <Image
            src={dxProdImages}
            className="picture expandable"
            onClick={onComparisonOpen}
            title={`${titleCase(dxProdName)} (click to zoom-in)`}
            style={{
              cursor: "zoom-in",
            }}
          />
          {renderLabel()}
        </div>
        <div className="dx-reference-info">
          <h3>{titleCase(dxProdName)}</h3>
          <div className="dx-match-additional-info">
            {isNullFactor && status && (
              <>
                Content {!dxProdContentsText ? "undefined" : "incomparable"}
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            {!status && !dxProdContentsText && <>Content unknown &nbsp;&nbsp;&nbsp;</>}
            {dxProdContentsText && (
              <>
                {dxProdContentsText}
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            <i className="dx-icon-label" />
            {titleCase(dxProdBrand || "Unknown")}
          </div>
          <div className="dx-match-ref">
            <EANList eans={eans} />
            {dxProdArticleNr && <div>Article nr.: {dxProdArticleNr}</div>}
          </div>
          <div className="dx-factor">
            <span
              className={`dx-factor-part ${
                dxProdPrice && enforcedFactor && enforcedFactor !== 1
                  ? "dx-price-crossed"
                  : ""
              }`}
            >
              <div className="dx-factor-label">Price</div>
              {dxProdPrice ? (
                <>
                  <span className="currency">{dxProdCurrencySymbol}</span>
                  {dxProdPrice.toFixed(2)}
                </>
              ) : (
                "N/A"
              )}
            </span>
            <span className="dx-factor-part">
              <div className="dx-factor-label">x Factor</div>
              {!loadingRefProd && (
                <FactorInput
                  className="dx-factor-button"
                  readOnly={!isUpdater}
                  productName={refProdName}
                  productPicture={refProdImages}
                  productCurrency={refProdCurrencySymbol}
                  productPrice={refProdPrice}
                  productNormalizedPrice={refProdNormalizedPrice}
                  productNormalizedUnit={refProdNormalizedUnit}
                  productUnit={refProdContentUnit}
                  productBrand={refProdBrand}
                  productUrl={refProdUrl}
                  matchName={dxProdName}
                  matchUnit={dxProdContentUnit}
                  matchPicture={dxProdImages}
                  matchPrice={dxProdPrice}
                  matchCurrency={dxProdCurrencySymbol}
                  matchBrand={dxProdBrand}
                  matchUrl={dxProdUrl}
                  defaultProductMultiplier={refProdContentMultiplier}
                  defaultProductContent={refProdContentValue}
                  defaultMatchMultiplier={dxProdContentMultiplier}
                  defaultMatchValue={dxProdContentValue}
                  currentFactor={enforcedFactor}
                  isNullFactor={isNullFactor}
                  onChange={onFactorChange}
                />
              )}
            </span>
            {dxProdPrice && enforcedFactor && enforcedFactor !== 1 && (
              <span className="dx-factor-part dx-factor-adjusted">
                <div className="dx-factor-label">Adjusted</div>
                <span className="currency">{refProdCurrencySymbol}</span>
                {(dxProdPrice * enforcedFactor).toFixed(2)}
              </span>
            )}
          </div>

          <div className="dx-match-article-link">
            <span className="dx-match-store">
              {dxProdFriendlyShopName}
              <span className="dx-match-country">
                {" "}
                ({dxProdCountry}) <CountryFlag code={dxProdCountry} />
              </span>
            </span>
            {dxProdSource === DX_SOURCE_FIELDTAKE && !dxProductOutdated && (
              <Tooltip classes={{ tooltip: "tooltip" }} title="Fieldtake">
                <PlaceIcon className="dx-product-source" />
              </Tooltip>
            )}
            {dxProdSource === DX_SOURCE_FIELDTAKE && dxProductOutdated && (
              <Tooltip
                classes={{ tooltip: "tooltip" }}
                title="This product is no longer available in store"
              >
                <PlaceOffIcon color="secondary" className="dx-product-source" />
              </Tooltip>
            )}
            {dxProdSource === DX_SOURCE_SCRAPING && dxProductOutdated && (
              <Tooltip
                classes={{ tooltip: "tooltip" }}
                title="This product is no longer available online"
              >
                <span className="outdated-warn">
                  <LinkOffIcon color="secondary" />
                </span>
              </Tooltip>
            )}
            {dxProdSource === DX_SOURCE_SCRAPING && !dxProductOutdated && dxProdUrl && (
              <span>
                :{" "}
                <a
                  href={dxProdUrl}
                  onClick={() =>
                    onNavigateToStore({
                      type: "daltix product",
                      url: dxProdUrl,
                      name: dxProdName,
                    })
                  }
                  target="match_source"
                >
                  View on website
                </a>
                <i className="dx-icon-external-link" />
              </span>
            )}
          </div>
        </div>
      </div>

      {status && (
        <QualityIndicator
          labels={labels}
          comment={comment}
          onLabelsChange={onLabelsChange}
          onCommentChange={onCommentChange}
        />
      )}
    </div>
  )
}

function MatchActionTooltip({
  action,
  matchedOnText,
  validatedOnText,
  validatedBy,
  children,
}) {
  return (
    <Tooltip
      classes={{
        tooltip: "tooltip",
      }}
      title={
        <>
          <div className="action">{action}</div>
          {matchedOnText && (
            <>
              <br />
              <div className="property">
                Matched on <span className="value">{matchedOnText}</span>
              </div>
            </>
          )}

          {validatedOnText && (
            <div className="property">
              Updated on <span className="value">{validatedOnText}</span> by{" "}
              <span className="value">{validatedBy}</span>
            </div>
          )}
        </>
      }
    >
      {children}
    </Tooltip>
  )
}

function ActionsColumn({
  loading,
  status,
  matched_on: matchedOn,
  validated_on: validatedOn,
  validated_by: validatedBy,
  // events
  onMatch,
  onApprove,
  onUndoApproval,
  onUnmatch,
  onRestore,
}) {
  const showButtons = !loading

  const isApprover = useRestricted({ requiredPermissions: PERM_APPROVE_MATCHES })
  const isMatcher = useRestricted({ requiredPermissions: PERM_CREATE_MATCHES })
  const isDiscarder = useRestricted({ requiredPermissions: PERM_DISCARD_MATCHES })

  const matchedOnText = useMemo(
    () => moment(matchedOn).format("YYYY-MM-DD"),
    [matchedOn],
  )
  const validatedOnText = useMemo(
    () => (validatedOn ? moment(validatedOn).format("YYYY-MM-DD") : ""),
    [validatedOn],
  )

  return (
    <div className="actions dx-table-cell dx-text-right">
      <div className="dx-button-group-block">
        {showButtons && (
          <>
            {status === undefined && (
              <MatchActionTooltip action="Click to match this product.">
                <Button
                  title="Click to match this product"
                  block
                  color="green"
                  iconLeft={<Icon type="plus" />}
                  disabled={!isMatcher}
                  onClick={onMatch}
                >
                  Match
                </Button>
              </MatchActionTooltip>
            )}

            {status === STATUS_REVIEW && (
              <MatchActionTooltip
                action="Click to approve this Match."
                matchedOnText={matchedOnText}
                validatedOnText={validatedOnText}
                validatedBy={validatedBy}
              >
                <Button
                  block
                  iconLeft={<Icon type="ok" />}
                  disabled={!isApprover}
                  onClick={onApprove}
                >
                  Approve
                </Button>
              </MatchActionTooltip>
            )}

            {status === STATUS_APPROVED && (
              <MatchActionTooltip
                action="Click to remove the approval."
                matchedOnText={matchedOnText}
                validatedOnText={validatedOnText}
                validatedBy={validatedBy}
              >
                <Button
                  block
                  iconLeft={<Icon type="ok" />}
                  color="green"
                  disabled={!isApprover}
                  onClick={onUndoApproval}
                >
                  Approved
                </Button>
              </MatchActionTooltip>
            )}

            {(status === STATUS_APPROVED || status === STATUS_REVIEW) && (
              <MatchActionTooltip
                action="Click to Unmatch these products."
                matchedOnText={matchedOnText}
                validatedOnText={validatedOnText}
                validatedBy={validatedBy}
              >
                <Button
                  block
                  color="orange"
                  iconLeft={<Icon type="trash" />}
                  disabled={!isDiscarder}
                  onClick={onUnmatch}
                >
                  Unmatch
                </Button>
              </MatchActionTooltip>
            )}

            {status === STATUS_DISCARDED && (
              <MatchActionTooltip
                action="Click to Re-match these products."
                matchedOnText={matchedOnText}
                validatedOnText={validatedOnText}
                validatedBy={validatedBy}
              >
                <Button
                  title="Click to Re-match these products."
                  block
                  iconLeft={<Icon type="plus" />}
                  disabled={!isDiscarder}
                  onClick={onRestore}
                >
                  <span
                    style={{
                      textDecoration: "none",
                      textDecorationColor: "transparent",
                    }}
                  >
                    Restore
                  </span>
                </Button>
              </MatchActionTooltip>
            )}
          </>
        )}

        {!showButtons && <ButtonsPlaceholder />}
      </div>
    </div>
  )
}

function Match({ error, ...props }) {
  if (error) {
    throw error
  }
  return (
    <div className="match-root" data-testid="match-cell">
      <MatchItem {...props} />
      <ActionsColumn {...props} />
    </div>
  )
}

export default Match
