import React, { useEffect, useRef } from "react"
import debounce from "lodash/debounce"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import InfiniteScroll from "react-infinite-scroller"
import LazyLoad, { forceCheck } from "react-lazyload"

import { TransitionGroup, CSSTransition } from "react-transition-group"
import clsx from "clsx"
import Match, { Loader } from "../match/Match"
import useRestricted from "../../hooks/use-restricted"
import { PERM_CREATE_MATCHES } from "../../util/permissions"

function MatchesPresenter({
  referenceProductId,
  source,
  matches = [],
  className = "",
  hasMore = false,
  onLoadMoreResults = () => null,
}) {
  const scrollEl = useRef(null)

  const isMaker = useRestricted({ requiredPermissions: PERM_CREATE_MATCHES })

  useEffect(() => {
    setTimeout(() => forceCheck(), 1000)
  }, [matches.length])

  const handleScroll = debounce(() => forceCheck(), 500)

  return (
    <div
      className={clsx(
        "suggested-matches-list fill-height scroll",
        { "read-only": !isMaker },
        className,
      )}
      ref={scrollEl}
      key={referenceProductId}
      onScroll={handleScroll}
      offset={200}
      data-testid="suggested-matches-list"
    >
      <List data-testid="matches-list">
        <InfiniteScroll
          pageStart={1}
          loadMore={onLoadMoreResults}
          initialLoad={false}
          hasMore={hasMore}
          loader={<ListItem key="infinite-scroll-key" />}
          useWindow={false}
          getScrollParent={() => scrollEl.current}
        >
          <TransitionGroup>
            {matches.map((match, matchIdx) => (
              <CSSTransition
                key={`item_${match.daltix_id}`}
                classNames="match"
                appear
                enter
                exit
                timeout={{ appear: 700, enter: 700, exit: 400 }}
              >
                <LazyLoad
                  height={250}
                  scrollContainer={scrollEl.current}
                  key={match.daltix_id}
                  once={false}
                  offset={300}
                  overflow
                  resize
                  scroll
                  unmountIfInvisible
                  placeholder={<Loader />}
                >
                  <Match
                    {...match}
                    matchId={match.id}
                    key={match.daltix_id}
                    referenceProductId={referenceProductId}
                    source={match.source || source}
                    matchIdx={matchIdx}
                    last={matchIdx === matches.length - 1}
                  />
                </LazyLoad>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </InfiniteScroll>
      </List>
    </div>
  )
}

export default MatchesPresenter
